<template>
  <v-menu offset-y>

    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        width="72"
        height="72"
        v-bind="attrs"
        v-on="user.avatar ? on : {click: onAvatarUploadClick}"
      >
        <v-avatar size="64">
          <v-img
            v-if="user.avatar"
            :src="user.avatar"
          />
          <v-icon
            v-else
            size="48"
          >mdi-account</v-icon>
        </v-avatar>
      </v-btn>

      <input
        type="file"
        accept="image/*"
        ref="avatarFile"
        hidden
        @change="onAvatarFileChange"
      />
    </template>

    <v-list dense>
      <v-list-item @click="onAvatarUploadClick">
        <v-list-item-icon>
          <v-icon>mdi-upload</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Neues Profilbild hochladen...</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="removeAvatar">
        <v-list-item-icon>
          <v-icon>mdi-delete</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profilbild entfernen</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

  </v-menu>
</template>


<script>
import apiClient from '@/api-client';

export default {
  name: 'AvatarMenu',
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  methods: {
    onAvatarUploadClick() {
      this.$refs.avatarFile.click()
    },
    async onAvatarFileChange(event) {
      await apiClient.users.uploadAvatar(this.user.id, event.target.files[0])
      this.$store.commit('SET_USER', await apiClient.whoAmI())
    },
    async removeAvatar() {
      await apiClient.users.removeAvatar(this.user.id)
      this.$store.commit('SET_USER', await apiClient.whoAmI())
    }
  }
}
</script>
