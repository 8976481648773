<template>
  <div>

    <AppBar>
      <v-btn
        text
        small
        @click="logout()"
      >
        <v-icon left>mdi-logout</v-icon>
        Abmelden
      </v-btn>
    </AppBar>

    <v-container class="pa-1 pa-sm-3">
      <v-card
        max-width="600"
        class="mx-auto pa-2"
      >

        <v-card-title>
          Persönliche Daten
        </v-card-title>

        <v-card-text>

          <div class="item-row pb-3">
            <div class="item-col-label">
              Profilbild
            </div>
            <div class="item-col-content">
              <AvatarMenu />
            </div>
          </div>

          <v-divider />

          <div class="item-row">
            <template v-if="editDisplayName">
              <div class="item-col-label d-none d-sm-block">Name</div>
              <div class="item-col-content">
                <v-text-field
                  autofocus
                  outlined
                  dense
                  hide-details
                  :label="$vuetify.breakpoint.xsOnly ? 'Name' : null"
                  v-model="editDisplayName.value"
                  @keyup.enter="setDisplayName(editDisplayName.value)"
                  @keyup.esc="editDisplayName = null"
                />
              </div>
              <div class="item-col-actions">
                <v-btn
                  icon
                  @click="editDisplayName = null"
                >
                  <v-icon>mdi-cancel</v-icon>
                </v-btn>
                <v-btn
                  icon
                  color="primary"
                  @click="setDisplayName(editDisplayName.value)"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </div>
            </template>
            <template v-else>
              <div class="item-col-label">Name</div>
              <div class="item-col-content">
                {{ user.displayName }}
              </div>
              <div class="item-col-actions">
                <v-btn
                  icon
                  small
                  @click="editDisplayName = {value: user.displayName}"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>
          </div>

          <v-divider />

          <div class="item-row">
            <div class="item-col-label">E-Mail</div>
            <div class="item-col-content">{{ user.email }}</div>
          </div>

          <v-divider />

          <div class="item-row">
            <div class="item-col-label">Passwort</div>
            <div class="item-col-content">{{ '&#x02022;'.repeat(12) }}</div>
            <div class="item-col-actions">
              <v-btn
                icon
                small
                :to="{name: 'user-profile-password'}"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </div>

        </v-card-text>

      </v-card>
    </v-container>

  </div>
</template>


<script>
import apiClient from '@/api-client'
import AppBar from '@/components/app-bar'
import AvatarMenu from '@/pages/user-profile.page/avatar-menu'

export default {
  name: 'UserProfilePage',
  components: {
    AppBar,
    AvatarMenu
  },
  data: () => ({
    editDisplayName: null
  }),
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
    },
    async setDisplayName(displayName) {
      displayName = displayName.trim()
      if (displayName !== this.user.displayName && /\S+/.test(displayName)) {
        const updatedUser = await apiClient.users.update(this.user.id, {displayName})
        this.$store.commit('SET_USER', updatedUser)
      }
      this.editDisplayName = null
    }
  }
}
</script>


<style lang="scss" scoped>
.container {
  height: calc(100% - 48px);
  overflow: auto;
}

.item-row {
  min-height: 64px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 599px) {
  .item-col-label {
    width: 6rem;
  }
}

@media screen and (min-width: 600px) {
  .item-col-label {
    width: 8rem;
  }
}

.item-col-content {
  flex: 1;
  color: rgba(0, 0, 0, 0.87);
}
</style>
